import React from 'react';
import { Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ToolOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const tools = [
  { path: '/ping', label: '进入 IP 探测工具', type: 'primary' },
  { path: '/subnet-calculator', label: '进入 子网计算器', type: 'default' },
  { path: '/nmap-scanner', label: '进入 Nmap 探测工具', type: 'dashed' },
];

const NetworkTools: React.FC = () => {
  return (
    <div style={outerContainerStyle}>
      <div style={innerContainerStyle}>
        <ToolOutlined style={iconStyle} />
        
        <Title level={2} style={titleStyle}>网络工具</Title>
        
        <Paragraph style={paragraphStyle}>
          欢迎使用网络工具页面，您可以在此访问 <strong>IP 探测工具</strong>、
          <strong>子网计算器</strong> 和 <strong>Nmap 探测工具</strong>。
          使用这些工具可以更方便地管理和监控您的网络。
        </Paragraph>
        
        <div style={buttonContainerStyle}>
          {tools.map(({ path, label, type }) => (
            <Link key={path} to={path}>
              <Button type={type as any} size="large">{label}</Button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

// 样式定义
const outerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '70vh',
  background: 'linear-gradient(135deg, #e0f7fa 30%, #fff 90%)',
  padding: '40px 20px',
};

const innerContainerStyle: React.CSSProperties = {
  background: '#ffffff',
  padding: '40px 20px',
  borderRadius: '8px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  textAlign: 'center',
};

const iconStyle: React.CSSProperties = {
  fontSize: '50px',
  color: '#1890ff',
  marginBottom: '16px',
};

const titleStyle: React.CSSProperties = {
  color: '#333',
  marginBottom: '10px',
};

const paragraphStyle: React.CSSProperties = {
  fontSize: '16px',
  color: '#666',
  marginBottom: '20px',
  lineHeight: '1.6',
};

const buttonContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  marginTop: '20px',
};

export default NetworkTools;