import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import NetworkTools from './components/NetworkTools';
import PingTool from './components/PingTool';
import SubnetCalculator from './components/SubnetCalculator';
import NmapScan from './components/PortScanner';
import { BASE_PATH } from './config'; // 引入路径前缀

const Navigation: React.FC = () => {
  const location = useLocation();

  if (location.pathname === BASE_PATH) {
    return null; // 在主页面隐藏导航栏
  }

  return (
    <nav
      style={{
        background: 'linear-gradient(90deg, #4facfe 0%, #00f2fe 100%)',
        padding: '15px 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <ul
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '30px',
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {routes.map(({ path, label }) => (
          <li key={path} style={{ position: 'relative' }}>
            <Link
              to={path}
              style={{
                ...linkStyle,
                color: location.pathname === `${BASE_PATH}${path}` ? '#ffe100' : '#ffffff', // 高亮当前路由
              }}
            >
              {label}
              <span
                style={{
                  ...underlineStyle,
                  transform: location.pathname === `${BASE_PATH}${path}` ? 'scaleX(1)' : 'scaleX(0)',
                }}
              ></span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

// 路由配置数组
const routes = [
  { path: '/', label: '网络工具', element: <NetworkTools /> },
  { path: '/ping', label: 'IP 探测工具', element: <PingTool /> },
  { path: '/subnet-calculator', label: '子网计算器', element: <SubnetCalculator /> },
  { path: '/nmap-scanner', label: 'Nmap 探测工具', element: <NmapScan /> },
];

// Link 样式
const linkStyle: React.CSSProperties = {
  color: '#ffffff',
  textDecoration: 'none',
  fontSize: '18px',
  fontWeight: 'bold',
  position: 'relative',
  transition: 'color 0.2s ease, transform 0.2s ease',
};

// 动态下划线动画
const underlineStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '-3px',
  left: '0',
  width: '100%',
  height: '2px',
  backgroundColor: '#ffe100',
  transform: 'scaleX(0)',
  transformOrigin: 'center',
  transition: 'transform 0.3s ease',
};

const App: React.FC = () => {
  return (
    <Router basename={BASE_PATH}>
      <div>
        <Navigation />
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </div>
    </Router>
  );
};

export default App;