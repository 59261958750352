import React, { useState } from 'react';
import { Table, Spin, Empty, Modal, Button, Divider } from 'antd';
import axios from 'axios';
import type { ColumnsType } from 'antd/es/table';

interface PortStatus {
  [key: number]: string | null;
}

interface ScanResult {
  Time: string;
  IP: string;
  PingLoss: string;
  PortStatus: PortStatus;
  ISP: string;
  RawNmapOutput: string;
  RawPingOutput: string;
  CombinedOutput: string;
}

const MTR_API_URL = 'https://ulwok.com/network/mtr-test';

interface ScanResultsTableProps {
  results: ScanResult[];
  loading: boolean;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  filterStatus: string; // 筛选条件
}

const ScanResultsTable: React.FC<ScanResultsTableProps> = ({
  results,
  loading,
  pageSize,
  onPageSizeChange,
  filterStatus,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalWidth, setModalWidth] = useState<number>(800); // 动态宽度

  // 公共样式
  const preStyle = { fontFamily: 'monospace', fontSize: '12px', whiteSpace: 'pre', overflowX: 'auto' } as const;

  // 表格列定义
  const columns: ColumnsType<ScanResult> = [
    { title: '序号', render: (_, __, index) => index + 1, key: 'Count' },
    { title: '时间', dataIndex: 'Time', key: 'Time' },
    { title: 'IP 地址', dataIndex: 'IP', key: 'IP' },
    { title: '运营商', dataIndex: 'ISP', key: 'ISP' },
    {
      title: '丢包率',
      dataIndex: 'PingLoss',
      key: 'PingLoss',
      render: (text: string, record: ScanResult) => {
        const pingLoss = parseFloat(text.replace('%', ''));
        const color = pingLoss > 50 ? 'red' : pingLoss > 10 ? 'orange' : 'green';
        return (
          <span
            style={{ color, cursor: 'pointer' }}
            onClick={() => handleOpenMTRModal(record.IP, 0)} // 使用端口0
          >
            {text}
          </span>
        );
      },
    },
    ...[21, 22, 23, 80, 443, 8080].map((port) => ({
      title: `端口 ${port}`,
      dataIndex: ['PortStatus', port.toString()],
      key: `Port ${port}`,
      render: (status: string | null, record: ScanResult) => (
        <span
          style={{ color: status === 'open' ? 'green' : status === 'unreachable' ? 'red' : 'gray', cursor: 'pointer' }}
          onClick={() => handleOpenMTRModal(record.IP, port)}
        >
          {status ?? 'N/A'}
        </span>
      ),
    })),
    {
      title: '详细信息',
      key: 'Details',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleOpenRawOutputModal(record.RawNmapOutput, record.RawPingOutput, record.IP)}
          style={{ padding: 0, textAlign: 'left' }}
        >
          查看详细
        </Button>
      ),
    },
  ];

  // 打开 MTR 测试 Modal
  const handleOpenMTRModal = async (ip: string, port: number) => {
    setModalTitle(`MTR 测试 - IP: ${ip} 端口: ${port === 0 ? 'N/A' : port}`);
    setModalWidth(800); // 设置 MTR 宽度
    setIsLoading(true);
    setModalVisible(true);

    try {
      const response = await axios.post(MTR_API_URL, { ip, port });
      setModalContent(
        <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%', background: '#f8f9fa' }}>
          <pre style={preStyle}>{response.data.result}</pre>
        </div>
      );
    } catch (error) {
      setModalContent(<div>无法获取 MTR 测试结果，请稍后重试。错误：{error instanceof Error ? error.message : '未知错误'}</div>);
    } finally {
      setIsLoading(false);
    }
  };

  // 打开 Nmap 和 Ping 详细信息 Modal
  const handleOpenRawOutputModal = (rawNmapOutput: string, rawPingOutput: string, ip: string) => {
    setModalTitle(`Nmap 和 Ping 测试详细信息 - ${ip}`);
    setModalWidth(1000); // 设置 Modal 宽度
    setModalContent(
      <div style={{ display: 'flex', gap: '16px', maxHeight: '400px', overflowY: 'hidden' }}>
        <div style={{ flex: 1, overflow: 'auto', background: '#f8f9fa', padding: '10px', borderRadius: '8px' }}>
          <h3>Nmap 扫描端口详细信息:</h3>
          <pre style={{ ...preStyle, fontSize: '11px', marginBottom: '16px' }}>{rawNmapOutput}</pre>
        </div>
        <div style={{ width: '1px', background: '#ddd' }} /> {/* 分割线 */}
        <div style={{ flex: 1, overflow: 'auto', background: '#f8f9fa', padding: '10px', borderRadius: '8px' }}>
          <h3>Ping 测试详细信息:</h3>
          <pre style={{ ...preStyle, fontSize: '11px' }}>{rawPingOutput}</pre>
        </div>
      </div>
    );
    setModalVisible(true);
  };  

  // 关闭 Modal
  const closeModal = () => {
    setModalVisible(false);
    setModalContent(null);
  };

  // 根据筛选条件过滤结果
  const filteredResults = results.filter((result) => {
    if (filterStatus === 'all') return true;
    if (filterStatus === 'unreachable') {
      return Object.values(result.PortStatus).some((status) => status === 'unreachable');
    }
    if (filterStatus === 'reachable') {
      return Object.values(result.PortStatus).some((status) => status && status !== 'unreachable');
    }
    return true;
  });

  return (
    <>
      <Spin spinning={loading}>
        {filteredResults.length === 0 ? (
          <Empty description="没有符合条件的扫描结果" />
        ) : (
          <Table
            dataSource={filteredResults}
            columns={columns}
            rowKey={(record) => `${record.IP}-${record.Time}`}
            pagination={{
              pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              onShowSizeChange: (_, size) => onPageSizeChange(size),
            }}
            scroll={{ x: 'max-content' }}
          />
        )}
      </Spin>

      {/* 通用 Modal */}
      <Modal
        title={
          <>
            {modalTitle}
            <Divider style={{ margin: '8px 0' }} /> {/* 添加分隔线 */}
          </>
        }
        open={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={modalWidth} // 动态宽度
      >
        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        ) : (
          modalContent
        )}
      </Modal>
    </>
  );
};

export default ScanResultsTable;
