import React, { useState } from 'react';
import { Input, Button, Typography, message, Spin, Card, Modal, Row, Col } from 'antd';
import axios from 'axios';

const { Title, Paragraph, Text, Link } = Typography;

// API 地址常量
const API_URL_IPV4 = 'https://ulwok.com/network/calculate-subnet';
const API_URL_IPV6 = 'https://ulwok.com/network/calculate-subnet-v6';

const SubnetCalculator: React.FC = () => {
  const [subnetIPv4, setSubnetIPv4] = useState<string>('');
  const [subnetIPv6, setSubnetIPv6] = useState<string>('');
  const [resultIPv4, setResultIPv4] = useState<any>(null);
  const [resultIPv6, setResultIPv6] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])$/;
  const ipv6Regex = /^([0-9a-fA-F]{1,4}:){1,7}([0-9a-fA-F]{1,4}|:)\/\d{1,3}$/;

  const handleCalculateIPv4 = async () => {
    if (!ipv4Regex.test(subnetIPv4)) {
      message.warning('请输入有效的 IPv4 地址和子网掩码（例如: 192.168.0.0/24）');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(API_URL_IPV4, { subnet: subnetIPv4 });
      setResultIPv4(response.data);
      setIsModalVisible(true);
    } catch (error) {
      message.error('计算子网时出错，请检查网段格式');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCalculateIPv6 = async () => {
    if (!ipv6Regex.test(subnetIPv6)) {
      message.warning('请输入有效的 IPv6 网段（例如: 2101:db6::/64）');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(API_URL_IPV6, { subnet: subnetIPv6 });
      setResultIPv6(response.data);
      setIsModalVisible(true);
    } catch (error) {
      message.error('IPv6 子网计算时出错，请检查网段格式');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderResultItem = (label: string, value: string, isIPv6: boolean = false) => (
    <Row gutter={16} align="middle" style={{ marginBottom: '8px', lineHeight: isIPv6 ? '1.6' : '1.4' }}>
      <Col span={isIPv6 ? 6 : 10} style={{ fontWeight: 500, color: '#595959', textAlign: 'right', fontSize: '14px', whiteSpace: 'nowrap' }}>
        {label}：
      </Col>
      <Col span={isIPv6 ? 18 : 14} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>
        <Text copyable style={{ color: '#333' }}>{value}</Text>
      </Col>
    </Row>
  );

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      background: '#f5f7fa',
      padding: '20px'
    }}>
      <Card
        style={{
          maxWidth: '500px',
          width: '100%',
          borderRadius: '12px',
          boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
          padding: '40px',
          textAlign: 'center',
          backgroundColor: '#ffffff'
        }}
      >
        <Title level={2} style={{ color: '#1c1c1c', marginBottom: '24px' }}>子网计算器</Title>
        <Paragraph style={{ fontSize: '15px', color: '#595959', marginBottom: '24px' }}>
          输入网段以计算子网信息
        </Paragraph>
        
        {/* IPv4 输入框和按钮 */}
        <Input
          placeholder="请输入 IPv4 网段 (e.g., 192.168.0.0/24)"
          value={subnetIPv4}
          onChange={(e) => setSubnetIPv4(e.target.value)}
          style={{
            marginBottom: '10px',
            height: '42px',
            fontSize: '16px',
            borderRadius: '8px'
          }}
        />
        <Button
          type="primary"
          onClick={handleCalculateIPv4}
          style={{
            width: '100%',
            height: '42px',
            fontSize: '16px',
            borderRadius: '8px',
            marginBottom: '20px'
          }}
        >
          计算 IPv4 子网
        </Button>

        {/* IPv6 输入框和按钮 */}
        <Input
          placeholder="请输入 IPv6 网段 (e.g., 2101:db6::/64)"
          value={subnetIPv6}
          onChange={(e) => setSubnetIPv6(e.target.value)}
          style={{
            marginBottom: '10px',
            height: '42px',
            fontSize: '16px',
            borderRadius: '8px'
          }}
        />
        <Button
          type="primary"
          onClick={handleCalculateIPv6}
          style={{
            width: '100%',
            height: '42px',
            fontSize: '16px',
            borderRadius: '8px'
          }}
        >
          计算 IPv6 子网
        </Button>

        {loading && <Spin size="large" style={{ marginTop: '20px' }} />}

        {/* 结果展示的模态框 */}
        <Modal
          title={resultIPv4 ? "IPv4 子网计算结果" : "IPv6 子网计算结果"}
          open={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            setResultIPv4(null);
            setResultIPv6(null);
          }}
          footer={null}
          centered
          width={resultIPv6 ? 800 : 500} // IPv6 固定宽度800px，IPv4 500px
        >
          <div style={{ padding: '24px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            {resultIPv4 && (
              <div>
                {renderResultItem("网段", resultIPv4.subnet)}
                {renderResultItem("总地址数量", String(resultIPv4.totalAddresses))}
                {renderResultItem("可用地址数量", String(resultIPv4.usableAddresses))}
                {renderResultItem("子网掩码", resultIPv4.subnetMask)}
                {renderResultItem("反掩码", resultIPv4.wildcardMask)}
                {renderResultItem("第一个可用地址", resultIPv4.firstUsableAddress)}
                {renderResultItem("最后一个可用地址", resultIPv4.lastUsableAddress)}
                {renderResultItem("广播地址", resultIPv4.broadcastAddress)}
                {renderResultItem("ALI网关地址", resultIPv4.gatewayAddress)}
              </div>
            )}

            {resultIPv6 && (
              <div>
                {renderResultItem("压缩地址", resultIPv6.compressed_address, true)}
                {renderResultItem("扩展地址", resultIPv6.expanded_address, true)}
                {renderResultItem("前缀字首", resultIPv6.prefix_mask, true)}
                {renderResultItem("范围起始地址", resultIPv6.range_start, true)}
                {renderResultItem("范围结束地址", resultIPv6.range_end, true)}
                {renderResultItem("数量/64s", String(resultIPv6.num_64s), true)}
                
                {/* 链接至外部计算器 */}
                <div style={{ marginTop: '20px' }}>
                  <Paragraph>
                    想要进一步拆分 IPv6 子网？请访问<Link href="https://www.838dz.com/calculator/5233.html" target="_blank">IPv6 子网计算器</Link>。
                  </Paragraph>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </Card>
    </div>
  );
};

export default SubnetCalculator;
