import React from 'react';
import { Collapse, Typography } from 'antd';

const { Paragraph } = Typography;

const InstructionCollapse: React.FC = () => {
  const items = [
    {
      key: '1',
      label: '端口扫描工具说明',
      children: (
        <Typography>
          <Paragraph>
            此工具用于扫描网络中的目标 IP 或网段，支持多种实用功能：
          </Paragraph>
          <ul>
            <li>输入单个 IP、IP 范围或多个逗号分隔的 IP 地址（支持 IPv4 和 IPv6）。</li>
            <li>通过 WebSocket 实时连接服务器，获取最新的扫描结果。</li>
            <li>根据端口状态筛选结果，例如显示 Reachable 或 Unreachable 的端口。</li>
            <li>根据获取到的扫描结果，可以进行MTR测试，点击结果进行测试。</li>
            <li>查看最近扫描的 IP 历史记录，方便快速重新扫描。</li>
            <li>支持将扫描结果详细信息下载为文件，以供后续分析。</li>
          </ul>
          <Paragraph>
            请确保输入的 IP 地址或范围格式正确，点击“扫描”按钮后等待扫描完成。
          </Paragraph>
          <Paragraph>
            扫描结果会实时显示在下方表格中，支持查看详细信息和下载所有扫描数据。
          </Paragraph>
        </Typography>
      ),
    },
  ];

  return <Collapse items={items} style={{ marginBottom: '20px' }} />;
};

export default InstructionCollapse;