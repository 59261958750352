import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, Button, message, Typography, Select, Row, Col, Modal } from 'antd';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { ColumnsType } from 'antd/es/table';
import './PingTable.css';

const { Title } = Typography;
const { Option } = Select;

interface PingResult {
  ip: string;
  isp: string;
  sent: number;
  received: number;
  loss: string;
  min_time: string | null;
  max_time: string | null;
  avg_time: string | null;
  success: boolean;
  public_ip: string;
  detailed_output: string;
  task_id: string;
}

const API_URL = 'https://ulwok.com/network/ping-service/ping';

const PingTable: React.FC = () => {
  const [ips, setIps] = useState<string>(() => localStorage.getItem('ips') || ''); // 保存历史IP
  const [data, setData] = useState<PingResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<string>('all');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedDetail, setSelectedDetail] = useState<any>({});
  
  useEffect(() => {
    setIsButtonDisabled(ips.trim() === '');
  }, [ips]);

  useEffect(() => {
    localStorage.setItem('ips', ips); // 保存IP历史
  }, [ips]);

  const hasUnreachable = data.some(item => !item.success);

  const filteredData = data.filter((item) => {
    if (filter === 'reachable') return item.success;
    if (filter === 'unreachable') return !item.success;
    return true;
  });

  const calculateColumnWidth = useCallback((title: string, dataIndex: keyof PingResult) => {
    const titleWidth = title.length * 16;
    const maxDataWidth = Math.max(...data.map(item => ((item[dataIndex] ?? '').toString().length))) * 16;
    return Math.max(titleWidth, maxDataWidth) + 16;
  }, [data]);

  const columns: ColumnsType<PingResult> = [
    {
      title: '序号',
      key: 'index',
      render: (_text, _record, index) => (currentPage - 1) * pageSize + index + 1,
      width: 70,
    },
    { title: '探测源 IP (阿里云主机)', dataIndex: 'public_ip', key: 'public_ip', width: calculateColumnWidth('探测源 IP (阿里云主机)', 'public_ip') },
    { title: '探测目标 IP', dataIndex: 'ip', key: 'ip', width: calculateColumnWidth('探测目标 IP', 'ip') },
    { title: '运营商', dataIndex: 'isp', key: 'isp', width: calculateColumnWidth('运营商', 'isp') },
    { title: '发送', dataIndex: 'sent', key: 'sent', width: calculateColumnWidth('发送', 'sent') },
    { title: '接收', dataIndex: 'received', key: 'received', width: calculateColumnWidth('接收', 'received') },
    { title: '丢包率', dataIndex: 'loss', key: 'loss', width: calculateColumnWidth('丢包率', 'loss') },
    {
      title: '最小时间',
      dataIndex: 'min_time',
      key: 'min_time',
      render: (min_time: string | null) => min_time ?? 'N/A',
      width: calculateColumnWidth('最小时间', 'min_time'),
    },
    {
      title: '最大时间',
      dataIndex: 'max_time',
      key: 'max_time',
      render: (max_time: string | null) => max_time ?? 'N/A',
      width: calculateColumnWidth('最大时间', 'max_time'),
    },
    {
      title: '平均时间',
      dataIndex: 'avg_time',
      key: 'avg_time',
      render: (avg_time: string | null) => (
        avg_time ? (
          <span title={`${avg_time} ms`}>
            {parseFloat(avg_time).toFixed(2)} ms
          </span>
        ) : 'N/A'
      ),
      width: calculateColumnWidth('平均时间', 'avg_time'),
    },
    {
      title: '可达结果',
      dataIndex: 'success',
      key: 'success',
      render: (success: boolean) => (
        <span style={{ color: success ? 'green' : 'red' }}>
          {success ? '可达' : '不可达'}
        </span>
      ),
      width: calculateColumnWidth('可达结果', 'success'),
    },
    {
      title: '详细',
      key: 'detail',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => showDetail(record)}
          style={{ display: 'block', textAlign: 'center' }}
        >
          查看详细
        </Button>
      ),
      width: 100,
      align: 'center',
    },
  ];

  const formatIps = (input: string): string => {
    return input
      .replace(/，/g, ',')
      .replace(/。/g, '.')
      .replace(/\s+/g, ',')
      .replace(/[\u3002]/g, '.');
  };

  const handlePing = async () => {
    setLoading(true);
    setInputDisabled(true);
    try {
      const ipList = Array.from(new Set(formatIps(ips).split(',').map(ip => ip.trim()).filter(ip => ip.length > 0)));
      if (ipList.length === 0) {
        message.warning('请输入至少一个有效IP地址');
        setLoading(false);
        setInputDisabled(false);
        return;
      }
  
      const response = await axios.post(API_URL, { ips: ipList }, {
        headers: {
          'x-api-key': 'your_secure_token_heres'
        }
      });
  
      if (response.data && response.data.results.length > 0) {
        // 包含 task_id 数据
        const resultsWithTaskId = response.data.results.map((item: any) => ({
          ...item,
          task_id: response.data.task_id,
        }));
        setData(resultsWithTaskId);
      } else {
        message.info('未找到任何结果');
        setData([]);
      }
    } catch (error: any) {
      if (error.response?.status === 403) {
        message.error('API Key 验证失败，请检查您的凭证');
      } else {
        message.error(error.response?.data?.message || 'Ping操作失败，请检查网络或IP格式');
      }
    } finally {
      setLoading(false);
      setInputDisabled(false);
    }
  };

  const exportToExcel = () => {
    const taskId = data[0]?.task_id || "Ping_Results"; // 使用第一个数据的 task_id 作为文件名
    const exportData = data.map((item, index) => ({
      序号: index + 1,
      探测源IP: item.public_ip,
      IP地址: item.ip,
      运营商: item.isp,
      发送: item.sent,
      接收: item.received,
      丢包率: item.loss,
      最小时间: item.min_time || 'N/A',
      最大时间: item.max_time || 'N/A',
      平均时间: item.avg_time || 'N/A',
      可达结果: item.success ? '可达' : '不可达',
      详细信息: item.detailed_output,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Ping Results');
    // XLSX.writeFile(workbook, 'Ping_Results.xlsx');
    XLSX.writeFile(workbook, `${taskId}.xlsx`)
  };

  const showDetail = (detail: PingResult) => {
    setSelectedDetail(detail);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedDetail({});
  };

  return (
    <div className="ping-table-container">
      <Title level={3} className="ping-table-title">IP 探测工具</Title>
      <p className="ping-table-description">此工具用于检测多个 IP 的网络连通性，请在下方输入框中输入以逗号分隔的 IP 地址。</p>
      <div className="input-container">
        <Input
          value={ips}
          onChange={(e) => setIps(formatIps(e.target.value))}
          placeholder="Enter IP addresses separated by commas (e.g., 8.8.8.8, 8.8.4.4)"
          style={{ width: '100%' }}
          disabled={inputDisabled}
        />
      </div>

      <Row gutter={16} justify="center" align="middle" className="action-row">
        <Col>
          <Button
            type="primary"
            onClick={handlePing}
            loading={loading}
            disabled={isButtonDisabled}
          >
            Ping IPs
          </Button>
        </Col>
        <Col>
          <Select
            defaultValue="all"
            style={{ width: 160 }}
            onChange={(value) => setFilter(value)}
            disabled={isButtonDisabled}
          >
            <Option value="all">显示全部</Option>
            <Option value="reachable">显示可达</Option>
            <Option value="unreachable" disabled={!hasUnreachable}>显示不可达</Option>
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={exportToExcel}
            disabled={data.length === 0}
          >
            导出数据
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="ip"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          onShowSizeChange: (current, size) => {
            setPageSize(size); 
            setCurrentPage(1); 
          },
          onChange: (page) => setCurrentPage(page),
        }}
        scroll={{ x: true, y: 400 }}
        className="ping-table"
      />

      <Modal
        title="探测结果"
        open={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={700}
      >
        <div className="modal-basic-info">
          <h3 className="modal-title">基本信息</h3>
          <p><strong>任务ID: </strong> <span>{selectedDetail.task_id}</span></p>
          <p><strong>丢包率: </strong> <span>{selectedDetail.loss}</span></p>
          <p><strong>探测目标IP: </strong> <span>{selectedDetail.ip}</span></p>
          <p><strong>探测源IP: </strong> <span>{selectedDetail.public_ip}</span></p>
          <hr />

          <div className="ping-details-header">
            <h3 className="modal-title">PING详情</h3>
            <Button
              type="link"
              className="modal-copy-button"
              onClick={() => navigator.clipboard.writeText(selectedDetail.detailed_output)}
            >
              复制详情
            </Button>
          </div>
          
          <pre className="ping-modal-content">{selectedDetail.detailed_output}</pre>
        </div>
      </Modal>
    </div>
  );
};

export default PingTable;
