import React, { useState, useRef, useEffect, useCallback, FC } from 'react';
import { Input, Button, Tooltip, message, Row, Col, Dropdown, Select, Card } from 'antd';
import { CloseCircleOutlined, DownOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { MenuProps } from 'antd';
import ScanResultsTable from './ScanResultsTable';
import InstructionCollapse from './InstructionCollapse'; // 引入新的 Collapse 组件

const { Option } = Select;

message.config({
  top: 80,
  duration: 2,
  maxCount: 3,
});

interface ScanResult {
  Time: string;
  IP: string;
  PingLoss: string;
  PortStatus: { [key: number]: string | null };
  ISP: string;
  RawNmapOutput: string;
  RawPingOutput: string;
  CombinedOutput: string;
}

const PortScanner: FC = () => {
  const [target, setTarget] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<ScanResult[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [history, setHistory] = useState<{ ip: string; timestamp: string }[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const websocketRef = useRef<WebSocket | null>(null);
  const inputRef = useRef<InputRef>(null);
  const resultsRef = useRef<HTMLDivElement | null>(null);

  const handleClearInput = useCallback(() => {
    setTarget('');
    inputRef.current?.focus();
  }, []);

  const connectWebSocket = useCallback(() => {
    if (!websocketRef.current || websocketRef.current.readyState === WebSocket.CLOSED) {
      websocketRef.current = new WebSocket('wss://ulwok.com/network/ws/nmap');

      websocketRef.current.onopen = () => {
        setIsConnected(true);
        message.success({ content: 'WebSocket 连接成功！', key: 'ws-connect' });

        if (websocketRef.current?.readyState === WebSocket.OPEN) {
          websocketRef.current.send('get_history');
        }
      };

      websocketRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          if (data.history) {
            setHistory(data.history);
          } else {
            setResults(Object.values(data));
          }
          setLoading(false);
        } catch {
          message.error('收到无效的 WebSocket 消息格式。');
          setLoading(false);
        }
      };

      websocketRef.current.onerror = () => {
        message.error('WebSocket 连接错误，请检查网络或服务器状态。');
        setIsConnected(false);
        setLoading(false);
      };

      websocketRef.current.onclose = () => {
        setIsConnected(false);
        message.warning('WebSocket 已断开，请手动重新连接。');
      };
    }
  }, []);

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.close();
      }
      websocketRef.current = null;
    };
  }, [connectWebSocket]);

  const handleAddressChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTarget(e.target.value.replace(/，/g, ',').replace(/。/g, '.').replace(/\s+/g, ','));
  }, []);

  const handleScan = useCallback(() => {
    if (!isConnected || websocketRef.current?.readyState !== WebSocket.OPEN) {
      message.error('WebSocket 未连接，请稍后重试');
      return;
    }
    if (!target) {
      message.error('请输入目标 IP、范围或多个逗号分隔的 IP');
      return;
    }
    setResults([]);
    setLoading(true);
    setFilterStatus('all');
    websocketRef.current.send(target);
  }, [target, isConnected]);

  useEffect(() => {
    if (results.length > 0 && !loading) {
      resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [results, loading]);

  const historyItems: MenuProps['items'] = history.length > 0
    ? history.map((item, index) => ({
        key: index.toString(),
        label: (
          <Tooltip title={`${item.ip} - ${item.timestamp}`}>
            {item.ip.length > 20 ? `${item.ip.slice(0, 20)}...` : item.ip} ({item.timestamp})
          </Tooltip>
        ),
        onClick: () => setTarget(item.ip),
      }))
    : [
        {
          key: 'no-history',
          label: (
            <div style={{ textAlign: 'center', color: '#999', fontStyle: 'italic' }}>
              没有扫描记录
              <Button
                type="link"
                onClick={() => inputRef.current?.focus()}
                style={{ padding: 0, marginLeft: 5 }}
              >
                开始第一次扫描
              </Button>
            </div>
          ),
          disabled: true,
        },
      ];

  const handleDownloadResults = () => {
    if (results.length === 0) {
      message.warning('没有可下载的扫描结果');
      return;
    }

    const combinedData = results.map(result => `IP: ${result.IP}\nTime: ${result.Time}\n${result.CombinedOutput}`).join('\n\n-----\n\n');
    const blob = new Blob([combinedData], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `scan_results_${Date.now()}.txt`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div style={{ padding: '20px', background: '#f0f2f5' }}>
      <InstructionCollapse /> {/* 引入 Collapse 组件 */}

      <Card>
        <Row gutter={[16, 8]} align="middle">
          <Col flex="auto">
            <Tooltip title="输入单个 IP 或多个逗号分隔的 IP (支持 IPv4/IPv6)">
              <Input
                ref={inputRef}
                placeholder="输入目标 IP 或多个 IP"
                value={target}
                onChange={handleAddressChange}
                suffix={
                  <CloseCircleOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClearInput();
                    }}
                    style={{ visibility: target ? 'visible' : 'hidden' }}
                  />
                }
                disabled={!isConnected || loading}
              />
            </Tooltip>
          </Col>
          <Col>
            <Dropdown menu={{ items: historyItems }} trigger={['click']} disabled={loading}>
              <Button>扫描IP记录 <DownOutlined /></Button>
            </Dropdown>
          </Col>
          <Col>
            <Button type="primary" onClick={handleScan} loading={loading} disabled={!target || loading}>扫描</Button>
          </Col>
          <Col>
            <Button
              type="default"
              onClick={connectWebSocket}
              disabled={isConnected}
              icon={isConnected ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}
              style={{ backgroundColor: isConnected ? '#f6ffed' : '#fff1f0', borderColor: isConnected ? '#b7eb8f' : '#ffa39e' }}
            >
              {isConnected ? '已连接' : '连接'}
            </Button>
          </Col>
          <Col>
            <Select
              defaultValue="all"
              style={{ width: 150 }}
              onChange={(value) => setFilterStatus(value)}
              value={filterStatus}
              disabled={results.length === 0}
            >
              <Option value="all">显示全部</Option>
              <Option value="unreachable">显示 Unreachable 端口</Option>
              <Option value="reachable">显示 Reachable 端口</Option>
            </Select>
          </Col>
          <Col>
            <Button
              type="default"
              onClick={handleDownloadResults}
              icon={<DownloadOutlined />}
              disabled={results.length === 0}
            >
              下载结果
            </Button>
          </Col>
        </Row>
      </Card>

      {history.length === 0 && !loading && (
        <Card style={{ marginTop: '20px', textAlign: 'center' }} bordered={false}>
          <p style={{ fontSize: '16px', color: '#595959' }}>首次使用？请输入目标 IP 进行扫描。</p>
          <Button type="primary" onClick={() => inputRef.current?.focus()}>立即开始</Button>
        </Card>
      )}

      <div ref={resultsRef}>
        <ScanResultsTable
          results={results}
          loading={loading}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          filterStatus={filterStatus}
        />
      </div>
    </div>
  );
};

export default PortScanner;
